import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useSpring, animated } from 'react-spring';
import { v4 as uuidv4 } from "uuid";

import styles from './GroundHandling.module.scss';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import OverviewCard from './components/OverviewCard/OverviewCard';
import { GroundHandlingActionParams, useGetChecklistProgressQuery, useGetChecklistsURLQuery, useGetGroundHandlingOperationsQuery, usePostStartGroundHandlingOperationMutation } from '@/features/api/copApiEndpoints/groundHandlingAPI';
import { updateError } from '@/app/mainSlice';
import { CircularProgress } from '@mui/material';
import { mapGroundHandlingOperationType } from './GroundHandlingFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faBarsProgress } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as Logo } from '@/media/adlc_logo_icon_w.svg';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import StopGroundHandlingModal from './components/StopGroundHandling/StopGroundHandlingModal';
import { useGetOrdersQuery } from '@/features/api/copApiEndpoints/orderAPI';



export function GroundHandling() {
  /* GroundHandling displays the functions (list of tasks) corresponding to the GroundHandlingOperation type. */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux states
  const groundHandlingState = useAppSelector((state) => state.groundHandling);
  const groundHandlingOperationStatus = groundHandlingState.ongoingGroundHandlingOperation.status;
  const groundHandlingOperationType = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.type;
  const orderId = groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.orderId;

  // Local states
  const [isShowStopPrepareModal, setIsShowStopPrepareModal] = useState(false);
  const [currentChecklist, setCurrentChecklist] = useState(groundHandlingState.ongoingGroundHandlingOperation.index.currentList);
  const [currentTask, setcurrentTask] = useState(groundHandlingState.ongoingGroundHandlingOperation.index.currentTask);
  const [cardId, setCardId] = useState<string[]>(Array.from({ length: groundHandlingState.ongoingGroundHandlingOperation.checklists.length }, (_, index) => `Item ${index + 1}`));
  
  // API call functions
  const [postStartGroundHandlingOperation, postStartGroundHandlingOperationResult] = usePostStartGroundHandlingOperationMutation(); 

  const { 
    isLoading: checklistsLoading,
    isSuccess: checklistsSuccess,
    isError: checklistsErr, 
    refetch: refetchChecklists,
  } = useGetChecklistsURLQuery(
    {
      type: groundHandlingOperationType,
      orderId: orderId 
    },
    {
      refetchOnFocus: true, // Enable refetch on window focus
    }
  );
  const { 
    isLoading: checklistsProgressLoading,
    isSuccess: checklistsProgressSuccess,
    isError: checklistsProgressErr, 
    refetch: refetchChecklistsProgress,
  } = useGetChecklistProgressQuery(
    {
      type: groundHandlingOperationType,
      orderId: orderId 
    },
    {
      refetchOnFocus: true, // Enable refetch on window focus
    }
  );

 // Post the start of the ground handling operation
 const {
  data: orderData,
  isLoading: orderLoading,
  isError: orderError
} = useGetOrdersQuery({
  timeStart: new Date(Date.now() - (2 * 60 * 60 * 1000)).toISOString(),
  timeEnd: new Date(Date.now() + (2 * 60 * 60 * 1000)).toISOString(),
}, {
  refetchOnFocus: false,
  skip: !orderId,
  refetchOnMountOrArgChange: false,
  pollingInterval: 30000
});

// Add error handling for order query
useEffect(() => {
  if (orderError) {
    dispatch(updateError({ id: '1', value: false }));
  }
}, [orderError, dispatch]);

// Post the start of the ground handling operation
useEffect(() => {
    if(groundHandlingOperationStatus[0] == undefined && groundHandlingOperationStatus[1] == 'ONGOING'){
      const paramsMutation: GroundHandlingActionParams = {orderId: orderId, type: groundHandlingOperationType};
      postStartGroundHandlingOperation(paramsMutation);
    }
  }, [groundHandlingOperationStatus])
  useEffect(() => {
    refetchChecklists();
  }, [])
  useEffect(() => {
    if (checklistsErr) {
      dispatch(updateError({ id: '1', value: false }));
    }
  }, [checklistsErr, checklistsProgressErr, dispatch]);
  useEffect(() => {
    for (let i = 0; i < groundHandlingState.ongoingGroundHandlingOperation.checklists.length; i++) {
      const updatedCardList = cardId;
      updatedCardList[i] = uuidv4();
      setCardId(updatedCardList);
    }
  }, [groundHandlingState])
  useEffect(() => {
    setCurrentChecklist(groundHandlingState.ongoingGroundHandlingOperation.index.currentList);
    setcurrentTask(groundHandlingState.ongoingGroundHandlingOperation.index.currentTask);
  }, [checklistsSuccess, checklistsProgressSuccess])


  const handleOnClick = (index: number) => {
    navigate(`/deliveries/groundHandling/${groundHandlingOperationType}/checklist`);
  }
  const [show, setShown] = useState(false);
  const props3 = useSpring({
    transform: show ? 'scale(1.03)' : 'scale(1)',
    boxShadow: show
      ? '0 20px 25px rgb(0 0 0 / 25%)'
      : '0 2px 10px rgb(0 0 0 / 8%)',
  });

  if (!checklistsLoading && !checklistsProgressLoading && checklistsSuccess && checklistsProgressSuccess) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <IconButton
              icon={faArrowRightFromBracket}
              content=""
              onClick={() => setIsShowStopPrepareModal(true)}
              rotate="rotate180"
              noBorder={true}
              color='unset'
            />
            {mapGroundHandlingOperationType(groundHandlingOperationType)}
        </div>        
        <div className={styles.subtitle}> 
          <div className={styles.info}>
            <div className={styles.item}>
              <div className={styles.item_title}>Order reference </div>
              <div className={styles.item_body}>{groundHandlingState.ongoingGroundHandlingOperation.groundHandlingOperation.orderRef}</div>
            </div>
          </div>
        </div>      
        <div className={styles.body}>
          <div className={styles.body_wrapper}>
            {groundHandlingState.ongoingGroundHandlingOperation.checklists.map((element, index) => (
              <div key={uuidv4()}>
                {index == currentChecklist - 1 ? (
                  <>
                  {currentTask == 1 ? (
                    <OverviewCard 
                      key={uuidv4()}                
                      title={element.title} 
                      currentTask={0} 
                      amountTasks={element.actions.length} 
                      status='current'
                      onClick={() =>handleOnClick(index)}
                    />
                  ):(
                    <OverviewCard 
                      key={uuidv4()}  
                      title={element.title} 
                      currentTask={currentTask} 
                      amountTasks={element.actions.length} 
                      status='current'
                      onClick={() =>handleOnClick(index)}
                    />
                  )}
                </>
                ):(
                  <>
                    {index < currentChecklist - 1 ? (
                      <OverviewCard 
                        key={uuidv4()}    
                        title={element.title} 
                        currentTask={element.actions.length} 
                        amountTasks={element.actions.length} 
                        status='previous'
                        onClick={() => {}}
                      />
                    ):(
                      <OverviewCard 
                        key={uuidv4()}  
                        title={element.title} 
                        currentTask={0} 
                        amountTasks={element.actions.length} 
                        status='next'
                        onClick={() => {}}
                      />
                    )}
                  </>
                )}
              </div>
            ))}
          </div>  
        </div>
        <StopGroundHandlingModal
          open={isShowStopPrepareModal}
          handleClose={() => setIsShowStopPrepareModal(false)}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.wrapper}>
        <div className={styles.body_wrapper}>
          <div className={styles.progress_wrapper}>
            <CircularProgress sx={{ color: 'rgb(6, 95, 217)' }} />
          </div>
        </div>
      </div>
    );
  }
}

